// @flow
import React from 'react'
import { useIntl } from 'react-intl'
import { graphql, Link } from 'gatsby'

import { withIntl } from 'src/intl'

import { linkToAppCoursesPage, linkToDefinitionsPage } from 'src/links'

import {
  Layout,
  Navbar,
  BackgroundImage,
  Footer,
  PageRow,
  Heading,
  PostContent,
} from 'src/common/components'
import HeadTags from 'src/components/HeadTags'
import { ButtonLink } from 'src/common/components/Button'

import styles from './definitions-post.module.scss'

type PageContext = {
  locale: string,
}

type Props = {
  data: Object,
  pageContext: PageContext,
}

const DefinitionsPost = ({ data, pageContext }: Props) => {
  const { locale } = useIntl()
  const { markdownRemark: post } = data
  const {
    title,
    meta: { pageTitle, metaDescription, ...otherMeta },
  } = post.frontmatter
  const { html, excerpt } = post
  const { footer } = data.footer.frontmatter

  return (
    <>
      <HeadTags
        pageContext={pageContext}
        pageTitle={pageTitle || title}
        metaDescription={metaDescription || excerpt}
        {...otherMeta}
      />
      <Layout>
        {{
          navbar: <Navbar pagePath={pageContext.pagePath} />,
          background: <BackgroundImage />,
          footer: <Footer content={footer} />,
          body: (
            <PageRow className={styles.container} firstRow>
              <div className={styles.body}>
                <Link
                  to={linkToDefinitionsPage(locale)}
                  className={styles.link}
                >
                  Back
                </Link>
                <Heading text={title} color="black" />
                <PostContent className={styles.content} content={html} />
                <h4>Want to learn more about {title}?</h4>
                <p className={styles.content}>
                  Join Seneca to get 250+ free exam board specfic A Level, GCSE,
                  KS3 & KS2 online courses.
                </p>
                <div className={styles.buttonContainer}>
                  <ButtonLink
                    color="blue"
                    external={true}
                    text="Start Learning"
                    linkExternal={linkToAppCoursesPage()}
                  />
                </div>
              </div>
            </PageRow>
          ),
        }}
      </Layout>
    </>
  )
}
export default withIntl(DefinitionsPost)

export const pageQuery = graphql`
  query defintionPostByID($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 150)
      frontmatter {
        title
        subject
        ...MetaFragment
      }
    }
    ...FooterFragment
  }
`
